<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'tradies' }"
          >Payment</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

import Form from '@/utils/form'

export default {
  name: 'PaymentDetails',

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      loading: false,
      deleteDialog: false,
      form: null,
      tabItems: [{ tab: 'Info' }],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      payment: (state) => state.payment.paymentDetails,
      authUser: (state) => state.auth.user,
    }),
  },

  methods: {
    ...mapActions({
      getPaymentDetails: 'payment/getPaymentDetails',
    }),

    async getPayment() {
      this.loading = true
      await this.getPaymentDetails(this.$route.params.id)
      this.form = new Form(this.payment)
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getPayment()
  },

  destroyed() {
    this.clearBusinessAreaDetails()
  },

  watch: {
    $route() {
      this.getPayment()
    },
  },
}
</script>
